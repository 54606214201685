body,
textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  resize: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}

textarea::-webkit-input-placeholder {
  /* Edge */
  color: red;
}

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

textarea::placeholder {
  color: grey;
  font-size: 15px;
  font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea {
  /* Remove IOS input shadow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  height: 24px;
}

textarea::-webkit-input-placeholder {
  font-size: 18px;
}

textarea.error::-webkit-input-placeholder,
input.error::-webkit-input-placeholder {
  color: red;
}

.form-control {
  border: solid 1px grey;
  padding: 10px 14px;
  border-radius: 20px;
  font-size: 17px;
  width: calc(100% - 30px);
}
.rdtTimeToggle,
.rdtSwitch {
  background-color: #36bbda;
  border-radius: 20px;
  padding: 5px 10px;
  text-align: center;
  color: white;
  font-weight: 500;
}
div.rdtPicker {
  position: relative;
}
div.rdtDays * {
  direction: ltr;
}
div.rdtPicker {
  box-shadow: none;
  border: none;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background: #ffffff00;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb66;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}
